.bg-scroll {
  background-color: #888aa8 !important;
  /* Arka plan rengi kaydırıldığında değişecek */
  transition: background-color 0.3s ease;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

#header.bg-scroll a:not(.dropdown-item) {
  color: white !important;
}

.navbar.navbar-expand-lg.center-nav.transparent.navbar-light img {
  filter: brightness(0) saturate(100%) invert(25%) sepia(61%) saturate(1644%)
    hue-rotate(198deg) brightness(95%) contrast(92%);
}
.bg-scroll img{
  filter:none !important;
}


.mobile {
  display:none;
}

.swiper-controls .swiper-navigation .swiper-button.swiper-button-next{
  right:-3rem;
  border:1px solid #ddd;
}
.swiper-controls .swiper-navigation .swiper-button.swiper-button-prev{
  left:-3rem;
  border:1px solid #ddd;
}
@media screen and (max-width: 1023px) {
  .mobile {
    display:block;
  }

  .swiper-controls .swiper-navigation .swiper-button.swiper-button-next{
    border:1px solid #ddd;
    right:1rem;
  }
  .swiper-controls .swiper-navigation .swiper-button.swiper-button-prev{
    left:1rem;
    border:1px solid #ddd;
  }
}


.contact-wrapper .navbar-dark > div > div > div > ul > li > a{
  color:#343f52 !important;
}
.contact-wrapper .navbar-dark img {
  filter: brightness(0) saturate(100%) invert(25%) sepia(61%) saturate(1644%)
  hue-rotate(198deg) brightness(95%) contrast(92%);
}
